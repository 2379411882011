import React, {useState } from 'react';
import "react-bootstrap/dist/react-bootstrap.min.js";
import Beebomapp from './Beebomapp';
import Demoapp from './Demoapp';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
function App() {
  const [key, setKey] = useState('beebom');

  const requiredStyles = {
    tabs:{
      listStyle: "none",
      display: "flex",
    },
    btn:{
      border: "0"
    }
  }
  
  return (
    <>
    <Tabs
      id="controlled-tab-example"
      defaultActiveKey="beebom"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className=""
      style={requiredStyles.tabs}
    >
      <Tab eventKey="beebom" title="Beeboom App" style={requiredStyles.btn}>
        {key==="beebom" && <Beebomapp />}
      </Tab>
      <Tab eventKey="home" title="Demo App" style={requiredStyles.btn}>
        {key==="home" && <Demoapp />}
      </Tab>
    </Tabs>
    </>
  
  );
}
export default App;
