import React, { useState, useEffect } from 'react';
const Beebomapp = () => {
    const [iframeWidth, setIframeWidth] = useState(window.innerWidth);
    const [iframeHeight, setIframeHeight] = useState(window.innerHeight);
    

    useEffect(() => {
        const handleResize = () => {
            setIframeWidth(window.innerWidth);
            setIframeHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <iframe
            title='beebomapp'
            src="https://shupaman-beebomapp.hf.space"
            border="0"
            width={iframeWidth}
            height={iframeHeight}
        ></iframe>

    )

}
export default Beebomapp;