import axios from "axios"
import { useState } from "react";
const Demoapp = () => {
    const [chatgpt, setChatgpt] = useState("")
    const fetchOpenAiResponse = async (prompt) => {
      try {
        const response = await axios.post("http://shupaman.com/server/",{
          prompt: prompt
        });
        const data = response.data;
        console.log("fetchOpenAiResponse ", data);
        setChatgpt(data.message);
      } catch (error) {
        console.error(error);
      }
    };
    const generatePrompt = () => {
        var promptType = document.getElementById("promptType").value;
        var topic = document.getElementById("topic").value;
        var emotion = document.getElementById("emotion").value;
        var prompt = "Can you generate a " + promptType + " prompt about " + topic + " that is " + emotion + "?";
        fetchOpenAiResponse(prompt);
      }
    return(
        <>
        <h1>ChatGPT Prompt Generator</h1>
        <label for="promptType">Prompt Type:</label>
        <select id="promptType">
            <option value="personal">Personal</option>
            <option value="professional">Professional</option>
        </select>
            <br/>
        <label for="topic">Topic:</label>
        <select id="topic">
            <option value="travel">Travel</option>
            <option value="food">Food</option>
            <option value="technology">Technology</option>
        </select>
        <br/>
            <label for="emotion">Emotion:</label>
            <select id="emotion">
                <option value="happy">Happy</option>
                <option value="excited">Excited</option>
                <option value="curious">Curious</option>
            </select>
            <br/>
            <button onClick={generatePrompt}>Generate Prompt</button>
            <br/>
            <p id="generatedPrompt">{chatgpt}</p>
        
        </>
    )

}
export default Demoapp;